<template lang="pug">
    div.pa-10#produto
        PageTitle(label="Clientes")

        div(v-acl.cant.hide='`client.list`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='`client.list`')

            v-data-table(
                fixed-header
                :headers="headers"
                :items="clients.data"
                :items-per-page="clients.per_page"
                class="elevation-1"
                :loading='clients.data.length <= 0'
                loading-text="Carregando... Aguarde!"
                hide-default-footer
                )
                template(v-slot:item.phone="{ item }") {{item.phone | phoneFormat}}

                template(v-slot:item.actions="{ item }")      
                    div(style="white-space:nowrap")          
                        //- v-icon(
                        //-     small
                        //-     class="mr-2"
                        //-     @click="viewItem(item)"
                        //-     title="Visualizar"
                        //- ) mdi-clipboard-text-outline

                        v-icon(
                            small
                            class="mr-2"
                            @click="editItem(item)"
                            title="Editar"
                            v-acl.can.disable='`client.edit`'
                        ) mdi-pencil
                        
                        //- v-icon(
                        //-     small
                        //-     @click="deleteItem(item)"
                        //-     title="Deletar"
                        //- )  mdi-delete
            paginate( :data="clients" )
            confirm(ref="confirm")
            dialog-client(ref="dialog_client" )
</template>

<script>
export default {
    name: "ClientsList",
    components:{
        confirm: () => import( '@/components/Confirm' ),
        'dialog-client': () => import( '@/components/DialogClient' ),
        paginate: () => import( '@/components/Paginate' ),
        PageTitle: () => import( '@/components/PageTitle' ),
    },
    data(){
        return {
            params: {},
            headers: [
                {
                    text: '#',
                    value: 'id',
                    sortable: false,
                    width: '1%',
                },
                {   text: 'NOME', value: 'fullname', sortable: false  },
                {   text: 'TELEFONE', value: 'phone', sortable: false  },
                {   text: '', value: 'actions', sortable: false, width: '1%', },
            ],
            clients: {
                data: []
            }
        }
    },

    created: function() {
        this.params = {...this.$route.query}// clone prevent bug
        this.loadData()
    },

    methods: {
        loadData: async function(){
            if ( this.acl('client.list') ) {
                let res = await this.api.get( 'clients', this.params )
                this.clients = res.data
            }

        },

        editItem: function (item){
            if(this.acl('client.edit')){
                this.$refs.dialog_client.open(item);
            }            
        },
    },

    


};
</script>
